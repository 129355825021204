@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
html {
  overflow: auto; }

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: auto;
  background-repeat: no-repeat; }

#root {
  height: 100%; }

.layout {
  height: 100%;
  padding: 62px 0px; }
  .layout__section {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .layout__section .MuiSwitch-root {
      float: right;
      width: 73px;
      height: 47px; }
      .layout__section .MuiSwitch-root .MuiSwitch-switchBase {
        width: 50px;
        height: 50px;
        top: -2px;
        left: 1px; }
        .layout__section .MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb {
          width: 100%;
          height: 32px; }
      .layout__section .MuiSwitch-root .MuiSwitch-thumb {
        background-image: url("/images/moon.svg");
        background-size: 100% 100%;
        background-position: center;
        background-size: 48% 100%;
        background-repeat: no-repeat; }
      .layout__section .MuiSwitch-root .MuiSwitch-colorPrimary.Mui-checked {
        color: #3C6E71; }
      .layout__section .MuiSwitch-root .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: #3C6E71; }
    .layout__section .social_media {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .layout__section .social_media i {
        font-size: 40px;
        margin-right: 32px;
        cursor: pointer;
        transition: 0.2s ease; }
        .layout__section .social_media i:hover {
          -webkit-transform: translateY(-4px);
                  transform: translateY(-4px); }
    .layout__section .title {
      padding-right: 240px; }
      .layout__section .title h1 {
        font-weight: bold;
        font-size: 48px;
        line-height: 68px; }
  .layout__section:nth-child(2) {
    height: 100%; }

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .layout {
    padding: 32px 0px; }
    .layout__section:nth-child(1) {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse; }
    .layout__section {
      width: 100%;
      padding-left: 15px; }
      .layout__section .MuiSwitch-root {
        margin-bottom: 92px; }
      .layout__section .title {
        padding-right: 0px; }
        .layout__section .title h1 {
          font-size: 40px; }
    .layout__section:nth-child(2) {
      height: 73%; } }

