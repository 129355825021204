@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
html{
  overflow: auto;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: auto;
  background-repeat: no-repeat;
}

#root{
  height: 100%;
}

// Layout
.layout{
  height: 100%;
  padding: 62px 0px;
  &__section{
    justify-content: space-between;
    align-items: center;
    .MuiSwitch-root{
      float: right;
      width: 73px;
      height: 47px;
      .MuiSwitch-switchBase{
        // padding: 0px !important;
        width: 50px;
        height: 50px;
        top: -2px;
        left: 1px;
        .MuiSwitch-thumb{
          width: 100%;
          height: 32px;
        }
      }
      .MuiSwitch-thumb{
        background-image: url("/images/moon.svg");
        background-size: 100% 100%;
        background-position: center;
        background-size: 48% 100%;
        background-repeat: no-repeat;
      }
      .MuiSwitch-colorPrimary.Mui-checked{
        color: #3C6E71;
      }
      .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
        background-color: #3C6E71;
      }
    }
    // Social Media Component
    .social_media{
      display: flex;
      align-items: center;
      i{
        font-size: 40px;
        margin-right: 32px;
        cursor: pointer;
        transition: 0.2s ease;
        &:hover{
          transform: translateY(-4px);
        }
      }
    }
    // Title
    .title{
      h1{
        font-weight: bold;
        font-size: 48px;
        line-height: 68px;
      }
      padding-right: 240px;
    }
  }
  &__section:nth-child(2){
    height: 100%;
  }
}

@media only screen and ( min-width: 0px ) and (max-width : 768px) {
  // Layout
.layout{
  padding: 32px 0px;
  &__section:nth-child(1){flex-direction: column-reverse;}
  &__section{
    width: 100%;
    padding-left: 15px;
    .MuiSwitch-root{
      margin-bottom: 92px;
    }
    // Social Media Component
    // .social_media{
    //   i{}
    // }
    // Title
    .title{
      h1{
        font-size: 40px;
      }
      padding-right: 0px;
    }
  }
  &__section:nth-child(2){
    height: 73%;
  }
}
}